/* @import url("./styles/fonts/font.css"); */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  height: 8px; /* Scrollbar height */
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #00000026; /* Scrollbar color */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track color */
}

html, body {
  overflow-x: hidden; /* Prevents unwanted horizontal scrolling */
}
@keyframes textLoading {
  0% { background-position: 100% 0; }
  100% { background-position: -100% 0; }
}

.loading-text {
  background: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0.3) 100%);
  background-size: 200% 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: textLoading 5s linear infinite;
}

